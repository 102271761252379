/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import carrer from './assets/MG_Webseite_Banner.jpg'

export default {
    heroContainer: {
        position: `relative`,
        mt: [6, 6],
        pt: [7, 7],
        pb: [7, 7],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: `full`,
            height: `1`,
            top: '-20px',
            right: 0
        },
        '::before': {
            zIndex: -3,
            bg: `white`,
            background: t => `linear-gradient(
        145deg,
        ${t.colors.alphaDarker} 0%,
        ${t.colors.alphaDarker} 100%
      )`
        },
        '::after': {
            zIndex: -2,
            background: `url(${carrer}) right top`,
            opacity: 0.4
        }
    }
}
